<template>
  <div
    v-if="!isIE"
    id="app"
    class="jade pagarme"
  >
    <div
      class="el-section el-centered eu-align-items_center"
      v-if="(isLoading && !hasError) || redirectOwner"
    >
      <emd-pagarme-loader
        :color="'secondary'"
        class="fade-in"
      />
    </div>
    <modal-default-config-mfa
      v-if="modalConfigMfaIsOpen"
      :isOpen="modalConfigMfaIsOpen"
      @onCancel="closeConfigMfaModal"
      @onSubmit="openConfigMFa"
      :title="$t('mfa-modal.title')"
      :textParagraph="[
        $t('mfa-modal.paragraph.first'),
        $t('mfa-modal.paragraph.second'),
        $t('mfa-modal.paragraph.third')
      ]"
      :textSubimit="$t('mfa-modal.submit')"
    />
    <global-error
      :theme="'pagarme'"
      v-if="!isLoading && hasError"
      :showLogo="componentIsEnabled('globalError', 'logo')"
      :code="code"
    />
    <delinquent v-else-if="!isLoading && isDelinquent" />
    <template v-else-if="!isLoading && !hasError && !isDelinquent">
      <modal-pending-account
        v-if="!redirectOwner && hasPendingStatus && !isKycRouteEnabled"
      />
      <router-view />
    </template>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { GlobalError, EmdPagarmeLoader } from 'emerald-vue/src/components'
import { datadogInitialize } from '@/vendor/datadog.vendor'
import { appcuesPage } from '@/vendor/appcues.vendor'
import Analitica from '@/vendor/analitica.vendor'
import { isPsP } from '@/enums/business-model.enum.js'
import { isOwner } from '@/enums/merchant-type.enum'
import { HTTP_STATUS_CODE } from '@/enums/http-status.enum'
import { setDateLocale } from '@/filters'
import ModalPendingAccount from './components/project/globals/ModalPendingAccount'
import Delinquent from './components/project/globals/Delinquent'

export default {
  name: 'App',
  components: {
    EmdPagarmeLoader,
    GlobalError,
    Delinquent,
    ModalPendingAccount,
    ModalDefaultConfigMfa: () =>
      import('@/components/project/globals/ModalMFA/ModalDefaultReset')
  },
  computed: {
    ...mapGetters({
      modalConfigMfaIsOpen: 'MODAL_CONFIG_MFA_IS_OPEN',
      merchant: 'merchant',
      merchantId: 'merchantId',
      isKycRouteEnabled: 'isKycRouteEnabled',
      componentIsEnabled: 'componentIsEnabled',
      isEmployee: 'isEmployee',
      globalLoading: 'globalLoading',
      globalError: 'globalError',
      globalErrorCode: 'globalErrorCode',
      profile: 'profile',
      merchantIsDelinquent: 'merchantIsDelinquent',
      account: 'account',
      accountId: 'accountId',
      pref: 'pref',
      appVersion: 'appVersion',
      onboardingStatus: 'onboardingStatus',
      businessModel: 'businessModel'
    }),
    isIE () {
      return window.isIE
    },
    isLoading () {
      return this.globalLoading
    },
    hasError () {
      return this.globalError
    },
    isDelinquent () {
      return this.profile && this.merchantIsDelinquent && !this.isEmployee
    },
    isOwner () {
      return this.merchant && isOwner(this.merchant.type.key)
    },
    code () {
      return this.globalErrorCode || HTTP_STATUS_CODE.INTERNAL_SERVER_ERROR
    },
    theme () {
      return process.env.VUE_APP_THEME
    },
    accountStatusIsPending () {
      return this.account.status ? this.account.status.key === 'pending' : false
    },
    businessModelIsPSP () {
      return isPsP(this.businessModel)
    },
    hasPendingStatus () {
      return (
        (this.accountStatusIsPending || this.onboardingStatus) &&
        this.businessModelIsPSP &&
        !this.isEmployee
      )
    },
    redirectOwner () {
      return (
        !this.accountStatusIsPending &&
        this.onboardingStatus &&
        this.businessModelIsPSP &&
        this.isOwner
      )
    }
  },
  watch: {
    $route (to, from) {
      appcuesPage()
      this.setDdLayer()
    }
  },
  created () {
    datadogInitialize()
  },
  beforeMount () {
    this.$i18n.locale = this.pref.language
    this.setLanguage()

    const body = document.body
    this.getPreferences().then(() => {
      const preferences = { ...this.pref }
      if (preferences.appearance.mode === 'light') {
        body.classList.remove('_mode_dark')
      } else {
        body.classList.add('_mode_dark')
      }
      this.setPreferences(preferences)
    })
  },
  mounted () {
    const meta = document.createElement('meta')
    meta.name = 'version'
    meta.content = this.appVersion
    document.getElementsByTagName('head')[0].appendChild(meta)

    Analitica.updateMetadata({
      user: {
        merchant_id: this.merchantId,
        account_id: this.accountId,
        user_email: this.profile?.user?.email
      }
    })
  },
  methods: {
    ...mapActions({
      setConfigMfaModal: 'SET_CONFIG_MFA_MODAL',
      setPreferences: 'SET_PREFERENCES',
      getPreferences: 'GET_PREFERENCES',
      setDdLayer: 'SET_DDLAYER'
    }),
    setLanguage () {
      const preferences = { ...this.pref }
      preferences.language = this.$i18n.locale
      setDateLocale(this.$i18n.locale)
      this.setPreferences(preferences)
    },
    openConfigMFa () {
      window.open(
        `${process.env.VUE_APP_PROFILE_URL}/security/authentication`,
        '_blank'
      )
      this.closeConfigMfaModal()
    },
    closeConfigMfaModal () {
      this.setConfigMfaModal(false)
    }
  }
}
</script>
<i18n>
  {
    "pt-BR": {
      "mfa-modal": {
        "title": "Autenticação em dois fatores necessária",
        "paragraph": {
          "first": "Para a sua segurança, ",
          "second": "essa ação só pode ser feita com a autenticação em dois fatores ativa ",
          "third": "na sua conta pessoal. Você pode ativar a autenticação em dois fatores no seu Perfil, na área de Segurança."
        },
        "submit": "Configurar autenticação em dois fatores"
      }
    },
    "en-US": {
      "mfa-modal": {
        "title": "Two-factor authentication required",
        "paragraph": {
          "first": "For your safety, ",
          "second": "this action can only be done with two-factor authentication enabled ",
          "third": "in your personal account. You can activate two-factor authentication in your Profile, in the Security area."
        },
        "submit": "Configure two-factor authentication"
      }
    }
  }
</i18n>
<style>
/* show underline on links inside tables */
tr:hover .emd-link,
a.emd-link:hover {
  text-decoration: underline;
}
.emd-modal__content > .emd-container {
  width: 100%;
}

.emd-color__url {
  color: var(--primrias-purple-100, #6045af);
}
</style>
